<template>
  <div class="maxHeight">
    <div class="header">
      <div class="title flex">
        <img src="../../assets/img/daily.png" alt="" width="28">
        <h2>饲养记录表</h2>
      </div>
    </div>
    <el-tabs v-model="activeName" class="contentBody">
      <el-tab-pane label="日历" name="first">
        <div class="flex between">
          <el-select v-model="roomId" size="small" placeholder="请选择房间号" class="selectRoom">
            <el-option
                v-for="item in options"
                :key="item.value"
                :label="(item.nameNo==null?'':item.nameNo)+item.name"
                :value="item.id">
            </el-option>
          </el-select>
          <div class="noRecord flex" v-if="Object.keys(oneDayList).length==0">
            <span class="selectTime">{{ dateV | formatDay1 }}</span>
            <span style="width: 100px;">当天无记录</span>
          </div>
          <div class="calendarRecord" v-else v-for="feed in oneDayList"
               :key="feed.id">
            <span class="selectTime">{{ dateV | formatDay1 }}</span>
            <p v-if="feed.state == 0"><span>操作：</span><span>不需要饲养</span>
            <p v-else>
              <span>操作：</span>
              <span>{{ feed.isReplFeedBox ? '饲养盒更换' : '' }}</span>&nbsp;&nbsp;
              <span>{{ feed.isReplCover ? '笼盖更换' : '' }}</span>&nbsp;&nbsp;
              <span>{{ feed.isReplPadding ? '垫料更换' : '' }}</span>&nbsp;&nbsp;
              <span>{{ feed.isReplBottle ? '饮水瓶更换' : '' }}</span>&nbsp;&nbsp;
              <span>{{ feed.isAddFeed ? '添加饲料' : '' }}</span>
            </p>
            <p><span>操作人：</span><span>{{ feed.createBy }}</span></p>
          </div>
        </div>
        <el-calendar class="calendar" v-model="dateV">
          <template slot="dateCell" slot-scope="{date,data}">
                  <span class="flex" :class=" (new Date().getTime()-new Date(data.day).getTime())> 0 ? 'pastTime':''">
                    <span class="flex" :class="withoutFeedList.includes(data.day)?'error':''">
                      <span class="flex"
                            :class="!withoutFeedList.includes(data.day)&& !commonList.includes(data.day)&& (new Date().getTime()-new Date(data.day).getTime())> 0?'noDa':''">
                        {{ data.day.split('-')[2] }}
                      </span>
                    </span>
                  </span>
          </template>
        </el-calendar>
      </el-tab-pane>
      <el-tab-pane label="列表" name="second">
        <div class="flex">
          <el-select v-model="value" size="small" filterable collapse-tags clearable
                     multiple class="selectRoom" placeholder="所有房间 " @change="search">
            <el-option
                v-for="item in options"
                :key="item.value"
                :label="(item.nameNo==null?'':item.nameNo)+item.name"
                :value="item.id"
            >
            </el-option>
          </el-select>
          <el-date-picker
              v-model="selectTime" size="small"
              type="daterange"
              class="timeRange"
              value-format="yyyy-MM-dd"
              format="yyyy-MM-dd"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              @change="search()">
          </el-date-picker>
          <el-input type="search" clearable class="keyRecord" size="small" placeholder="请输入操作人"
                    v-model="key"></el-input>
          <el-button type="primary" @click="search" size="small" class="search">搜索</el-button>
        </div>
        <el-table
            :data="tableData"
            max-height="calc(100vh - 413px)" height="calc(100vh - 413px)"
            :header-cell-style="{'background':'#EAF3FF','color': '#09BEE9',}"
            class="record-table">
          <el-table-column
              prop="createDate"
              label="日期"
              align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.createDate">{{ scope.row.createDate | formatDay }}</span>
            </template>
          </el-table-column>
          <el-table-column
              prop="room.name"
              label="房间"
              align="center"
              show-overflow-tooltip>
          </el-table-column>
          <el-table-column
              align="center"
              width="180"
              label="饲养盒更换">
            <template slot-scope="scope">
              <span v-if="scope.row.isReplFeedBox==true" style="color:#333;">是</span>
              <span v-else style="color:#bbb;">否</span>
            </template>
          </el-table-column>
          <el-table-column
              align="center"
              label="笼盖更换">
            <template slot-scope="scope">
              <span v-if="scope.row.isReplCover==true" style="color:#333;">是</span>
              <span v-else style="color:#bbb;">否</span>
            </template>
          </el-table-column>
          <el-table-column
              label="垫料更换"
              align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.isReplPadding==true" style="color:#333;">是</span>
              <span v-else style="color:#bbb;">否</span>
            </template>
          </el-table-column>
          <el-table-column
              label="饮水瓶更换"
              align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.isReplBottle==true" style="color:#333;">是</span>
              <span v-else style="color:#bbb;">否</span>
            </template>
          </el-table-column>
          <el-table-column
              label="添加饲料"
              align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.isAddFeed==true" style="color:#333;">是</span>
              <span v-else style="color:#bbb;">否</span>
            </template>
          </el-table-column>
          <el-table-column
              prop="createBy"
              label="操作人"
              align="center"
              show-overflow-tooltip>
          </el-table-column>
        </el-table>
        <div class="flex pagination ">
          <el-pagination
              prev-text="<"
              next-text=">"
              @current-change="handleCurrentChange"
              :current-page="page.current"
              :page-size="page.size"
              layout="prev, pager, next"
              :total="page.total">
          </el-pagination>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>

</template>

<script>
import moment from 'moment'

moment.locale('zh-cn')
export default {
  name: "animalFeed",
  data() {
    return {
      activeName: "first",
      roomId: "",
      options: [],
      oneDayList: [],
      dateV: new Date(),
      withoutFeedList: [],
      commonList: [],
      selectTime: '',
      key: "",
      value: [],
      tableData: [],
      page: {
        total: 1,
        pageSize: 8,
        pageNum: 1,
      },
    }
  },
  watch: {
    dateV: { // 监视搜索字段
      handler() {
        this.getDataFromServer();
      },
      deep: true
    },
    roomId: {
      handler() {
        this.getDataFromServer();
      },
      deep: true
    }
  },
  mounted() {
    this.istRoomByFType();
    this.findRecords();
  },
  methods: {
    getDataFromServer() {
      if (this.roomId == '') {
        this.$message.error("请选择房间号！");
        return;
      }
      if (this.dateV > (new Date())) {
        this.$message.error("暂无记录");
        return;
      }
      let startDate = new Date(this.dateV).getTime() - 8 * 3600 * 1000;
      //console.log(startDate);
      // 发起请求
      this.$get("/daily/ac-daily-feed/byOneDay/" + this.roomId, {
        startDate: startDate, // 搜索条件
      }).then(resp => { // 这里使用箭头函数
        //this.feed = resp.data;
        this.oneDayList = resp.data.oneDayList;
        this.commonList = resp.data.commonList;
        this.withoutFeedList = resp.data.withoutFeedList;
        if (this.commonList != null) {
          for (let i = 0; i < this.commonList.length; i++) {
            this.commonList[i] = moment(this.commonList[i]).format("YYYY-MM-DD");
          }
        }
        if (this.withoutFeedList != null) {
          for (let i = 0; i < this.withoutFeedList.length; i++) {
            this.withoutFeedList[i] = moment(this.withoutFeedList[i]).format("YYYY-MM-DD");
          }
        }
      })
    },
    istRoomByFType() {
      let fType = 1;
      this.$get("/daily/ac-daily-feed/listStock", {
            type: fType
          }
      ).then(res => {
        this.options = res.data.rows;
      })
    },
    search() {
      this.findRecords();
    },
    handleCurrentChange(val) {
      this.page.pageNum = val;
      this.findRecords();
    },
    handleSizeChange(val) {
      this.page.pageSize = val;
      this.page.pageNum = 1;
      this.findRecords();
    },
    findRecords() {
      this.$get('/daily/ac-daily-feed', {
        selectTime: this.selectTime,
        roomId: this.value,
        key: this.key,
        current: this.page.pageNum,
        size: this.page.pageSize,
      }).then(res => {
        this.tableData = res.data.rows;
        this.page.total = res.data.total;
        //this.page = res.data.total;
      }).catch(err => {
        this.$message.error("暂无数据！");
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import "src/assets/css/daily";
</style>
